/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { history } from "store";
import styles from "./app-layout.module.css";
import classnames from "classnames/bind";
import SliderWindow from "components/slider-window/slider-window";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  CurrentWindowConfig,
  setCurrentWindowConfig,
  setSliderWindowConfig,
  SliderState,
  SliderWindowConfig
} from "store/slider/slider";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { Publisher } from "api/route-data/route-data";
import { get } from "lodash";
import { navigate } from "utils/routes.utils";

const cx = classnames.bind(styles);

interface PartialAppState {
  slider: SliderState;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
  publisher: Publisher;
}

interface DispatchProps {
  setCurrentWindowConfig: (currentWindowConfig: Partial<CurrentWindowConfig>) => void;
  setSliderWindowConfig: (sliderWindowConfig: Partial<SliderWindowConfig>) => void;
  navigateToUrl: (url: string) => void;
}

interface StateProps {
  isDesktopSizeViewport: boolean;
  currentWindowConfig: CurrentWindowConfig;
  sliderWindowConfig: SliderWindowConfig;
  editorUrl: string;
}

interface OwnProps {
  children: React.ReactNode;
}

type Props = OwnProps & DispatchProps & StateProps;

export type WindowNavigateToUrl = (url: string) => void;

export const AppLayout: React.FC<Props> = ({
  children,
  setCurrentWindowConfig,
  currentWindowConfig,
  isDesktopSizeViewport,
  setSliderWindowConfig,
  sliderWindowConfig,
  editorUrl,
  navigateToUrl
}: Props) => {
  // Check if the current window is rendered inside a slider window
  useEffect(() => {
    const parentIframeElement = window.frameElement;
    if (parentIframeElement && parentIframeElement.getAttribute("title") === "slider-window") {
      setCurrentWindowConfig({ isInSlider: true });
    }
  }, [setCurrentWindowConfig]);

  // Enable slider only if,
  // 1) Current window should be a main window, not slider
  // 2) In desktop view
  useEffect(() => {
    const enabled = !currentWindowConfig.isInSlider && isDesktopSizeViewport;
    if (enabled && editorUrl) {
      const sanitizedEditorUrl = new URL(editorUrl).origin;
      setSliderWindowConfig({
        enabled,
        iframeUrl: `${sanitizedEditorUrl}/workspace/published`
      });
    } else {
      setSliderWindowConfig({
        enabled: false
      });
    }
  }, [currentWindowConfig, isDesktopSizeViewport, setSliderWindowConfig, editorUrl]);

  const toggleSlider = useCallback(() => {
    setSliderWindowConfig({ ...sliderWindowConfig, open: !sliderWindowConfig.open });
  }, [setSliderWindowConfig, sliderWindowConfig]);

  // If view port is not desktop and slider is still open, then close sidebar
  useEffect(() => {
    if (!isDesktopSizeViewport && sliderWindowConfig.open) {
      toggleSlider();
    }
  }, [isDesktopSizeViewport, sliderWindowConfig, toggleSlider]);

  // Attach a window function which will be called by slider to navigate to a page in main window
  useEffect(() => {
    if (!currentWindowConfig.isInSlider) {
      const windowNavigateToUrlFn: WindowNavigateToUrl = (url: string) => {
        navigateToUrl(url);
      };
      window["navigateToUrl"] = windowNavigateToUrlFn;
    }
  }, [navigateToUrl, currentWindowConfig]);

  const showSliderWindow = isDesktopSizeViewport && sliderWindowConfig.enabled && sliderWindowConfig.iframeUrl;
  return (
    <div
      data-test-id={"app-layout"}
      className={cx("app-layout", {
        "app-layout--grid": sliderWindowConfig.enabled,
        "app-layout--slider-open": sliderWindowConfig.open
      })}>
      <div data-test-id={"main-layout"} id="main-layout" className={cx("main")}>
        <ConnectedRouter history={history}>{children}</ConnectedRouter>
      </div>
      {showSliderWindow && (
        <div data-test-id={"slider-window"} className={cx("slider")}>
          <SliderWindow
            url={sliderWindowConfig.iframeUrl}
            onSliderToggleFn={toggleSlider}
            isSliderOpen={sliderWindowConfig.open}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    currentWindowConfig: state.slider.currentWindow,
    sliderWindowConfig: state.slider.sliderWindow,
    editorUrl: get(state, ["config", "publisher", "editor-url"])
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    setCurrentWindowConfig: (currentWindowConfig: Partial<CurrentWindowConfig>) => {
      return dispatch(setCurrentWindowConfig(currentWindowConfig));
    },
    setSliderWindowConfig: (sliderWindowConfig: Partial<SliderWindowConfig>) => {
      return dispatch(setSliderWindowConfig(sliderWindowConfig));
    },
    navigateToUrl: (url: string) => dispatch(navigate(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
