/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useState } from "react";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { AiStoryTranslationConfig, PartialAppState } from "api/workspace";
import styles from "./story-card.module.css";
import {
  AdvancedContextMenu,
  AdvancedContextMenuItem,
  AdvancedContextSubmenu
} from "components/advanced-context-menu/advanced-context-menu";
import MoreVertical from "components/icons/more-vertical";
import { get, noop } from "lodash";
import { getPublishedStory } from "api/story";
import { t } from "i18n";
import { getLanguageName } from "utils/language";
import { ThunkDispatch } from "redux-thunk";
import { translateStoryAction, cloneStoryAction, getStoryTranslationsAction } from "pages/workspace/action-creators";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import { StoryTranslation } from "helpers/api";
import { NavLink } from "react-router-dom";
import { WindowNavigateToUrl } from "containers/app-layout/app-layout";

interface OwnProps {
  id: string;
  frontEndUrl: string;
  switchToDeleteStoryRoute: (id: string) => void;
  canDeleteStory: boolean;
  isStoryPublished: boolean;
  storyStatus: string;
  canCloneStory: boolean;
}

interface StateProps {
  aiStoryTranslations: AiStoryTranslationConfig[];
  storyClones?: any;
  isCurrentWindowIsInSlider: boolean;
}

interface DispatchProps {
  translateStory: (storyId: string, translationConfig: AiStoryTranslationConfig) => void;
  getStoryTranslations: (
    storyId: string,
    translationConfig: AiStoryTranslationConfig
  ) => Promise<{ translations: StoryTranslation[]; error?: string }>;
  cloneStory: (storyId: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const getPublishedStoryUrl = async (storyId) => {
  try {
    const { story }: any = await getPublishedStory(storyId);
    return story && story.url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const StoryCardMenu: React.FC<Props> = ({
  id,
  frontEndUrl,
  switchToDeleteStoryRoute,
  canDeleteStory,
  isStoryPublished,
  storyStatus,
  aiStoryTranslations,
  translateStory,
  canCloneStory,
  cloneStory,
  getStoryTranslations,
  isCurrentWindowIsInSlider
}) => {
  const [translationModalState, setTranslationModalState] = useState<{
    showModal: boolean;
    translateStoryFn: () => void;
    existingTranslation: StoryTranslation | null;
  }>({ showModal: false, translateStoryFn: noop, existingTranslation: null });

  const goToStoryHandler = useCallback(async () => {
    const url = await getPublishedStoryUrl(id);
    window.open(url || frontEndUrl, "_blank");
  }, [frontEndUrl, id]);

  const toggleTranslationConfirmationModal = () => {
    setTranslationModalState({ ...translationModalState, showModal: !translationModalState.showModal });
  };

  const handleStoryTranslate = async (storyId: string, translationConfig: AiStoryTranslationConfig) => {
    const { translations, error } = await getStoryTranslations(storyId, translationConfig);
    if (!error) {
      const existingTranslation = get(translations, 0);
      if (existingTranslation) {
        setTranslationModalState({
          showModal: true,
          translateStoryFn: () => {
            translateStory(storyId, translationConfig);
          },
          existingTranslation
        });
      } else {
        translateStory(storyId, translationConfig);
      }
    }
  };

  const showTranslationSubMenu = aiStoryTranslations.length > 2;
  const languageLabel = (language: string) => {
    const prefix = showTranslationSubMenu ? "" : `${t("workspace.story_card.translate_to")} `;
    return `${prefix}${getLanguageName(language)}`;
  };

  const translationMenuItems = () => (
    <>
      {aiStoryTranslations.map((storyTranslationConfig) => {
        const language = languageLabel(storyTranslationConfig["target-language"]);
        const targetPublisherId = get(storyTranslationConfig, ["target-publisher", "id"]);
        const dataTestId = `story-${id}-translate-to-lang-${storyTranslationConfig["target-language"]}${
          targetPublisherId ? `-to-pub-${targetPublisherId}` : ""
        }`;
        return (
          <AdvancedContextMenuItem
            onClick={() => handleStoryTranslate(id, storyTranslationConfig)}
            data-test-id={dataTestId}>
            <div className={styles["story-translation"]}>
              <p className={styles["story-translation-language"]}>{language}</p>
              {storyTranslationConfig["target-publisher"] && (
                <p className={styles["story-translation-publisher"]}>{`${t("workspace.story_card.send_to")} ${
                  storyTranslationConfig["target-publisher"].name
                }`}</p>
              )}
            </div>
          </AdvancedContextMenuItem>
        );
      })}
    </>
  );

  const createPushNotificationURL = `/content/push-notifications/all/new?story-id=${id}`;

  const handleCreatePushNotification = () => {
    if (isCurrentWindowIsInSlider) {
      const mainWindowNavigateToUrlFn: WindowNavigateToUrl = get(window, ["parent", "navigateToUrl"]);
      if (mainWindowNavigateToUrlFn) {
        mainWindowNavigateToUrlFn(createPushNotificationURL);
      }
    }
  };

  const supressNavLinkClickInSidebar = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isCurrentWindowIsInSlider) {
      e.preventDefault();
    }
  };

  const showStoryCardMenu = isStoryPublished || canDeleteStory || aiStoryTranslations.length > 0 || canCloneStory;
  return (
    <>
      {showStoryCardMenu && (
        <div id={`story-card-menu-${id}`} className={styles["story-card-menu"]} data-test-id="story-card-menu">
          <AdvancedContextMenu
            data-test-id={`story-${id}-menu`}
            menuButton={<MoreVertical />}
            direction={"left"}
            align={"start"}>
            {isStoryPublished && (
              <AdvancedContextMenuItem onClick={goToStoryHandler}>
                <span>{t("workspace.story_card.go_to_story")}</span>
              </AdvancedContextMenuItem>
            )}
            {storyStatus === "published" && (
              <AdvancedContextMenuItem onClick={handleCreatePushNotification}>
                <NavLink
                  data-test-id={"create-push-notification"}
                  onClick={supressNavLinkClickInSidebar}
                  to={createPushNotificationURL}
                  className={styles["story-push-notification"]}>
                  <span>{t("workspace.story_card.create_push_notification")}</span>
                </NavLink>
              </AdvancedContextMenuItem>
            )}
            {canDeleteStory && (
              <AdvancedContextMenuItem onClick={() => switchToDeleteStoryRoute(id)}>
                <span>{t("workspace.story_card.delete")}</span>
              </AdvancedContextMenuItem>
            )}
            {showTranslationSubMenu ? (
              <AdvancedContextSubmenu
                data-test-id={`story-${id}-sub-menu-translate-to`}
                label={t("workspace.story_card.translate_to")}>
                {translationMenuItems()}
              </AdvancedContextSubmenu>
            ) : (
              translationMenuItems()
            )}
            {canCloneStory && (
              <AdvancedContextMenuItem onClick={() => cloneStory(id)}>
                <span>{t("workspace.story_card.clone")}</span>
              </AdvancedContextMenuItem>
            )}
          </AdvancedContextMenu>
        </div>
      )}
      <ConfirmationModal
        showConfirmationModal={translationModalState.showModal}
        headerText={t("workspace.translation.messages.update-translation-modal.header")}
        text={
          <div>
            <p>
              {t("workspace.translation.messages.update-translation-modal.message")}{" "}
              <a
                href={get(translationModalState, ["existingTranslation", "editor-url"])}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => toggleTranslationConfirmationModal()}>
                {t("workspace.translation.messages.update-translation-modal.existing-story-link")}
              </a>
              <br />
              <br />
              {t("workspace.translation.messages.update-translation-modal.confirm-action")}
            </p>
          </div>
        }
        confirmButtonLabel={t("workspace.translation.translate-btn")}
        cancelButtonLabel={t("common.cancel")}
        onConfirm={() => {
          toggleTranslationConfirmationModal();
          translationModalState.translateStoryFn();
        }}
        onCancel={() => toggleTranslationConfirmationModal()}
        confirmButtonVariant={"none"}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    translateStory: (storyId, translationConfig) => dispatch(translateStoryAction(storyId, translationConfig)),
    getStoryTranslations: (storyId: string, translationConfig: AiStoryTranslationConfig) =>
      dispatch(getStoryTranslationsAction(storyId, translationConfig)),
    cloneStory: (storyId) => dispatch(cloneStoryAction(storyId))
  };
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    aiStoryTranslations: get(state.config["ai-content-translation"], "story", []),
    isCurrentWindowIsInSlider: get(state, ["slider", "currentWindow", "isInSlider"], false)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoryCardMenu);
