/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import startCase from "lodash/startCase";
import classnames from "classnames/bind";
import { t } from "i18n";
import { storyWordCount, storyCharacterCount } from "utils/story.utils";
import { timeInMinutes } from "utils/reading-time.utils";
import { navigate } from "utils/routes.utils";
import { Story } from "api/story";
import { StoryId } from "api/primitive-types";
import { STORY_EDITOR_MANAGE_PATH, STORY_EDITOR_TEMPLATE_PATH } from "pages/story-editor/routes";
import { ConnectionState, RealtimeMember } from "helpers/ably/types";
import { setBannerAction } from "pages/story-editor/async-action-creators";
import { selectIsReadOnly } from "pages/story-editor/selectors";
import { actions } from "pages/story-editor/actions";
import { Spacer } from "@quintype/em/components/spacer";
import Chip from "components/chip/chip";
import RealTimeMembers from "pages/story-editor/components/realtime-members/realtime-members";
import ButtonWithIcon from "components/button/button-with-icon";
import Controller from "components/icons/controller";
import Button from "components/button/button";
import styles from "pages/story-editor/story-editor.module.css";
import Tooltip from "components/tooltip/tooltip";
import { formatNumberToMetricSystem } from "utils";
import { connectionStateBannerUI, lockStoryForEditUI, LockStoryUiState } from "pages/story-editor/lock-story-for-edit";

const cx = classnames.bind(styles);

interface StateProps {
  story: Story;
  isDesktopSizeViewport: boolean;
  isBlockConcurrentStoryEditEnabled: boolean;
  isReadOnly: boolean;
}

interface DispatchProps {
  updateStoryLockState(value: boolean): void;
  openTemplateInspector(storyId): void;
  setBannerMessage(banner): void;
  openManageInspector(storyId, template): void;
}

interface OwnProps {
  timelineOpenCls: any;
  storyInspectorOpenCls: any;
  previewOpenCls: any;
  seoScoreOpenCls: any;
  slideWindowOpenCls: string;
  toggleStoryEdit: (toggle: boolean) => void;
  realtimeMembers: RealtimeMember[];
  realtimeCurrentMember: RealtimeMember;
  realtimeConnectionState: ConnectionState;
  readOnlyStoryVersionId: StoryId;
  storyId: StoryId;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ManageBar: React.FC<Props> = ({
  timelineOpenCls,
  previewOpenCls,
  seoScoreOpenCls,
  storyInspectorOpenCls,
  isDesktopSizeViewport,
  openTemplateInspector,
  story,
  readOnlyStoryVersionId,
  isBlockConcurrentStoryEditEnabled,
  isReadOnly,
  storyId,
  realtimeMembers,
  realtimeCurrentMember,
  setBannerMessage,
  realtimeConnectionState,
  toggleStoryEdit,
  openManageInspector,
  updateStoryLockState,
  slideWindowOpenCls
}) => {
  const isNewStory = storyId === "new";
  const [connectivityState, setConnectivityState] = useState<ConnectionState>(realtimeConnectionState);

  const [template, setTemplate] = useState(story["story-template"]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const templateValue: any = story["story-template"] || searchParams.get("template");
    setTemplate(templateValue);
  }, [story]);

  useEffect(() => {
    if (connectivityState && connectivityState !== realtimeConnectionState) {
      const bannerUi = connectionStateBannerUI(realtimeConnectionState);
      setBannerMessage(bannerUi.banner);
    }
    setConnectivityState(realtimeConnectionState);
  }, [realtimeConnectionState, connectivityState, setBannerMessage]);

  const [uiState, setUiState] = useState<LockStoryUiState>(
    lockStoryForEditUI([], {}, isNewStory, realtimeConnectionState)
  );
  useEffect(() => {
    if (isBlockConcurrentStoryEditEnabled) {
      const newUiState = lockStoryForEditUI(realtimeMembers, realtimeCurrentMember, isNewStory, connectivityState);
      setUiState(newUiState);
    }
  }, [realtimeMembers, realtimeCurrentMember, isNewStory, isBlockConcurrentStoryEditEnabled, connectivityState]);

  useEffect(() => {
    let updateStoryLockFn;
    if (isBlockConcurrentStoryEditEnabled && uiState.connectionState !== ConnectionState.CONNECTING) {
      updateStoryLockFn = setTimeout(() => updateStoryLockState(uiState.lockStatus === "closed"), 250);
    }
    return () => updateStoryLockFn && clearTimeout(updateStoryLockFn);
  }, [uiState, isBlockConcurrentStoryEditEnabled, updateStoryLockState]);

  const settingsContainer = cx(
    "settings-container",
    timelineOpenCls,
    previewOpenCls,
    seoScoreOpenCls,
    storyInspectorOpenCls,
    slideWindowOpenCls
  );

  const storyTemplate = template;
  const numberOfWordsInStory = storyWordCount(story);
  const numberOfCharactersInStory = storyCharacterCount(story);
  const wordCharCountOnHoverText = `${numberOfCharactersInStory} ${t("story-editor.storyCharacterCountMsg", {
    count: numberOfCharactersInStory
  })}`;

  const numberOfCharactersInMetricSystem = formatNumberToMetricSystem(numberOfCharactersInStory);
  const wordCharCountText = `${numberOfWordsInStory} ${t("story-editor.storyWordCountMsg", {
    count: numberOfWordsInStory
  })} / ${numberOfCharactersInMetricSystem} ${t("story-editor.storyCharacterCountMsg", {
    count: numberOfCharactersInStory
  })} / ${timeInMinutes(story)} ${t("story-editor.storyReadingTimeMsg")}`;

  return (
    <div className={settingsContainer} data-test-id="settings-container">
      {isDesktopSizeViewport && <Spacer token="--space-xs" />}
      <div className={styles["story-template-story-size"]}>
        <div
          className={cx("story-template-settings", { disabled: isReadOnly })}
          data-test-id="story-template-settings-btn"
          onClick={() => !isReadOnly && openTemplateInspector(storyId)}>
          <Chip
            value={startCase(t(`story-editor.story-template.${storyTemplate}`, storyTemplate))}
            invert
            classname={"story-editor-chip"}
          />
        </div>
        {isDesktopSizeViewport ? (
          <span
            data-for={"story-size-tooltip"}
            data-tip={true}
            className={styles["story-size"]}
            data-test-id="story-size-span">
            {wordCharCountText}
            <Tooltip id={"story-size-tooltip"} effect="solid" value={wordCharCountOnHoverText} />
          </span>
        ) : (
          <span className={styles["story-size"]} data-test-id="story-size-span">
            {wordCharCountText}
          </span>
        )}
      </div>

      {!readOnlyStoryVersionId && (
        <div className={styles["manage-settings"]} data-test-id="manage-settings">
          {isBlockConcurrentStoryEditEnabled && !isNewStory ? (
            <RealTimeMembers
              toggleStoryEdit={toggleStoryEdit}
              isDesktopSizeViewport={isDesktopSizeViewport}
              uiState={uiState}
            />
          ) : (
            <div />
          )}
          {isDesktopSizeViewport ? (
            <ButtonWithIcon
              testId="story-editor-manage-btn"
              type="secondary"
              onClick={() => openManageInspector(storyId, storyTemplate)}
              icon={Controller}
              dir="left">
              {t("story-editor.manage-button")}
            </ButtonWithIcon>
          ) : (
            <Button
              testId="story-editor-manage-btn"
              type="secondary"
              onClick={() => openManageInspector(storyId, storyTemplate)}
              classname={"story-editor-manage-btn-mobile"}>
              <Controller />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state): StateProps {
  return {
    story: state.storyEditor.story as Story,
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport,
    isBlockConcurrentStoryEditEnabled: state.features.isBlockConcurrentStoryEditEnabled,
    isReadOnly: selectIsReadOnly(state)
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    updateStoryLockState: (value) => dispatch({ type: actions.UPDATE_STORY_LOCK, payload: { value } }),
    openTemplateInspector: (storyId) => dispatch(navigate(STORY_EDITOR_TEMPLATE_PATH, { id: storyId })),
    setBannerMessage: (banner) => dispatch(setBannerAction(banner)),
    openManageInspector: (storyId, template) =>
      dispatch(navigate(STORY_EDITOR_MANAGE_PATH, { id: storyId }, { template: template }))
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ManageBar);
