/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./slider-window.module.css";
import classnames from "classnames/bind";
import { t } from "i18n";
import Chevron from "../icons/chevron";

const cx = classnames.bind(styles);

type Props = {
  url: string;
  onSliderToggleFn: () => void;
  isSliderOpen: boolean;
};

const SliderWindow: React.FC<Props> = ({ url, onSliderToggleFn, isSliderOpen }: Props) => {
  return (
    <div className={cx("slider-window")}>
      <div className={cx("slider-seperator")}></div>
      <div className={cx("toggle")} onClick={onSliderToggleFn} data-test-id={"slider-toggle"}>
        <div className={cx("toggle__content")}>
          <span className={cx("toggle__arrow", { "toggle__arrow--open": isSliderOpen })}>
            <Chevron fill="var(--white)" width={20} height={20} variant="left" />
          </span>
          <p>{isSliderOpen ? t("common.hide") : t("common.show")}</p>
        </div>
      </div>
      <iframe className={cx("iframe-content")} title={"slider-window"} src={url} />
    </div>
  );
};

export default SliderWindow;
