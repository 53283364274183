/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import { TAB_LIST } from "./tab-list";
import wrapPage from "containers/page/page";
import { formatNumberToMetricSystem } from "utils";
import { updateBulkAccessLevel, getDefaultFilter } from "helpers/api";
import { navigateFn } from "../../utils/routes.utils";
import buildSearchQuery from "./build-search-query";
import * as actions from "./actions";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import {
  fetchWorkspaceData,
  fetchWorkspaceSearchData,
  deleteWorkspaceStory,
  saveFiltersAction,
  updateFilterAction,
  updatePageReferer
} from "./action-creators";
import WorkspaceActionBar from "pages/workspace/components/action-bar/action-bar";
import { ScrollTabList, Panel } from "components/tabs/tabs";
import BulkInputBar from "pages/workspace/components/bulk-input-bar/bulk-input-bar";
import Header from "components/header/header";
import SaveFilterInspector from "pages/workspace/components/save-filter-inspector/save-filter-inspector";
import BulkActionInspector from "pages/workspace/components/bulk-action-inspector/bulk-action-inspector";
import SearchBar from "components/search-bar/search-bar";
import Button from "components/button/button";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import StoryList from "pages/workspace/components/story-list/story-list";
import ActiveFiltersList from "pages/workspace/components/active-filters-list/active-filters-list";
import FiltersInspector from "./components/filters-inspector/filters-inspector";
import { t } from "i18n";
import styles from "./workspace.module.css";
import {
  WORKSPACE_TABS_PATH,
  WORKSPACE_FILTERS_PATH,
  WORKSPACE_DELETE_STORY_PATH,
  WORKSPACE_SAVE_FILTER_PATH
} from "./routes";
import { SelectTabs } from "./components/select-tabs/select-tabs";
import {
  PartialAppState,
  Errors,
  Features,
  FilterSet,
  SavedFilter,
  Source,
  Status,
  Story,
  StoryTemplate,
  Tab
} from "api/workspace";
import { match } from "react-router";
import { Location } from "history";
import { ThunkDispatch } from "redux-thunk";
import Filter from "components/icons/filter";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { get } from "lodash";

const cx = classnames.bind(styles);

interface WorkspaceStateProps {
  tabs?: Tab[];
  features?: Features;
  stories?: Story[];
  hits?: number;
  storyTemplates: StoryTemplate[];
  counts: Status;
  selectedTabSlug: string;
  currentFilterSet: FilterSet;
  showSavedFilterList?: boolean;
  sources?: Source[];
  isBulkActionEnabled?: boolean;
  bulkSelectedStoryIds: string[];
  bulkAccessLevelValue?: number | null;
  allStoriesSelected?: boolean;
  showLoadingIndicator?: boolean;
  showPublicPreviewLink?: boolean;
  canDeleteStory?: boolean;
  selectedSavedFilter: SavedFilter;
  errors?: Errors;
  isDesktopSizeViewport: boolean;
  title?: string;
  pageReferer: string | null;
  isCurrentWindowIsInSlider: boolean;
}

interface WorkspaceDispatchProps {
  navigate: (path: string, params?: { [key: string]: number | string | boolean }) => void;
  loadData: (defaultFilter?: { [key: string]: any }) => void;
  loadSearchData: (defaultFilter?: { [key: string]: any }) => void;
  onClose: (tabSlug: string) => void;
  loadTab: (tabSlug: string) => void;
  switchToTab: (tabSlug: string) => void;
  toggleFiltersInspector: (tabSlug: string) => void;
  updateFilter: (key: string, value: any) => Promise<void>;
  enableBulkAction: () => void;
  updateStoryIds: (id: string) => Promise<void>;
  saveFilter: (tabSlug: string) => void;
  saveFilters: (filterSet: FilterSet, filterName: string, tabSlug: string) => void;
  updateBulkSelected: (tabCounts: number) => void;
  clearFilters?: () => void;
  resetFilter: () => void;
  switchToDeleteStoryRoute: (storyId: string, tabSlug: string) => void;
  deleteStory: (selectedTabSlug: string, onClose: (tabSlug: string) => void) => void;
  removeErrors: (key: string) => void;
  updateSavedFilter: (savedFilter: SavedFilter, filterSet: FilterSet) => void;
  resetStories: () => void;
  notificationSuccess: (message: string) => void;
  notificationError: (message: string) => void;
  updatePageReferer: (referer: string | null) => void;
}

interface OwnProps {
  showFilterInspector: boolean;
  showBulkActionInspector?: boolean;
  showSaveFilterInspector?: boolean;
  showStoryDeleteModal: boolean;
  showSavedFilterList?: boolean;
  match: match<{ tabSlug: string; storyId: string }>;
  location?: Location;
}

type WorkspaceProps = WorkspaceStateProps & WorkspaceDispatchProps & OwnProps;

class Workspace extends React.Component<WorkspaceProps, any> {
  componentDidMount() {
    const { isCurrentWindowIsInSlider, sources, loadData, pageReferer, updatePageReferer } = this.props;
    if (isCurrentWindowIsInSlider) {
      const isPublisherHavingMultipleSources = sources && sources.length > 0;
      if (isPublisherHavingMultipleSources) {
        this.setStoriesOnFilterChange("source", {
          source: "all",
          categories: []
        });
      } else {
        loadData();
      }
    } else {
      if (pageReferer === "storyEditor") {
        loadData();
      } else {
        getDefaultFilter().then((filter) => {
          //if default filters are present load page with that, otherwise load page with publisher source filter, if sources are present.
          if (filter) {
            loadData(filter);
          } else {
            sources && sources.length > 0
              ? this.setStoriesOnFilterChange("source", {
                  source: "publisher",
                  categories: []
                })
              : loadData();
          }
        });
      }
      updatePageReferer(null);
    }
  }

  componentDidUpdate(prevProps: WorkspaceProps) {
    if (this.props.selectedTabSlug !== prevProps.selectedTabSlug) {
      this.props.loadData();
    }
  }

  setStoriesOnFilterChange = (key: string, value: any) => {
    this.props.updateFilter(key, value).then(() => {
      this.props.resetStories();
      this.props.loadData();
    });
  };

  setStoriesOnSearchFilterChange = (key: string, value: any) => {
    this.props.updateFilter(key, value).then(() => {
      this.props.resetStories();
      this.props.loadSearchData();
    });
  };

  loadMoreStories = () => {
    this.props.updateFilter("limit", this.props.currentFilterSet.limit + 20).then(() => this.props.loadData());
  };

  applyAccessLevel = () => {
    const { currentFilterSet, bulkAccessLevelValue, allStoriesSelected, bulkSelectedStoryIds } = this.props;
    let query = buildSearchQuery(currentFilterSet);
    query["access-level-value"] = bulkAccessLevelValue;
    if (!allStoriesSelected && bulkSelectedStoryIds.length) {
      query["story-content-ids"] = bulkSelectedStoryIds;
    }
    updateBulkAccessLevel(query).then(
      () => {
        this.props.onClose(this.props.selectedTabSlug);
        this.props.notificationSuccess(t("workspace.set_access_success"));
      },
      () => {
        this.props.notificationError(t("workspace.set_access_failure"));
      }
    );
  };

  updateStoryIds = (id: string) => {
    this.props
      .updateStoryIds(id)
      .then(() => this.props.updateBulkSelected(this.props.counts[this.props.selectedTabSlug]));
  };

  componentWillUnmount() {
    this.props.resetFilter();
  }

  render() {
    const allTabsList = this.props.tabs
      ? [{ title: "all", "status-group": "all" }, ...this.props.tabs]
      : (TAB_LIST(t) as Tab[]);
    const tabsWithNumbers = allTabsList.map((tab: Tab) => {
      let statusGroup = tab["status-group"] || tab["value"];
      let badgeValue = this.props.counts[statusGroup];
      return {
        label: tab["status-group"] ? t(`workspace.tabs.${tab["status-group"]}`) : tab.label,
        value: statusGroup,
        totalCount: badgeValue,
        badge: formatNumberToMetricSystem(badgeValue || 0)
      };
    });

    const { isDesktopSizeViewport, isCurrentWindowIsInSlider } = this.props;

    return (
      <React.Fragment>
        <div className={cx("page-container workspace")} data-test-id="page-container-workspace">
          <div className={styles["workspace-actions"]} data-test-id="workspace-actions">
            <div className={styles["workspace-search"]} data-test-id="workspace-search">
              <SearchBar
                value={this.props.currentFilterSet.term}
                onChange={(value) => this.setStoriesOnSearchFilterChange("term", value)}
                placeholder={t("workspace.search_placeholder")}
              />
              <div data-test-id="active-filters-list">
                <ActiveFiltersList
                  selectedSavedFilter={this.props.selectedSavedFilter}
                  filterSet={this.props.currentFilterSet}
                  clearFilters={this.props.clearFilters}
                  saveFilter={() => this.props.saveFilter(this.props.selectedTabSlug)}
                  isDesktopSizeViewport={isDesktopSizeViewport}
                  updateFilter={() =>
                    this.props.updateSavedFilter(this.props.selectedSavedFilter, this.props.currentFilterSet)
                  }
                />
              </div>
            </div>
            <div className={styles["workspace-action-button"]} data-test-id="workspace-action-btn">
              {isDesktopSizeViewport ? (
                <div data-test-id="filter-button-desktop">
                  <Button
                    testId="filter-button"
                    id="filter-button-desktop"
                    type="secondary"
                    onClick={() => this.props.toggleFiltersInspector(this.props.selectedTabSlug)}>
                    {t("workspace.filters")}
                  </Button>
                </div>
              ) : (
                <div data-test-id="filter-button-mobile">
                  <Button
                    testId="filter-button"
                    id="filter-button-mobile"
                    type="transparent"
                    onClick={() => this.props.toggleFiltersInspector(this.props.selectedTabSlug)}>
                    <Filter />
                  </Button>
                </div>
              )}
            </div>
            {isDesktopSizeViewport && (
              <div className={styles["workspace-action-button"]} data-test-id="workspace-action-btn">
                <Button
                  type="secondary"
                  id="bulk-action"
                  testId="bulk-action-button"
                  onClick={() => this.props.enableBulkAction()}>
                  {t("workspace.bulk_action")}
                </Button>
              </div>
            )}
          </div>
          <div className={styles["workspace-lanes"]} data-test-id="workspace-lanes">
            {isDesktopSizeViewport ? (
              <div data-test-id="scroll-tab-list">
                <ScrollTabList
                  tabs={tabsWithNumbers}
                  value={this.props.selectedTabSlug}
                  onChange={this.props.switchToTab}
                  className={"workspace-scroll-tablist"}
                />
              </div>
            ) : (
              <div data-test-id="select-tabs">
                <SelectTabs
                  options={tabsWithNumbers}
                  value={this.props.selectedTabSlug}
                  onChange={this.props.switchToTab}
                />
              </div>
            )}
            <Panel>
              <div className={styles["workspace-tab-panel"]} data-test-id="workspace-tab-panel">
                {this.props.isBulkActionEnabled && this.props.counts[this.props.selectedTabSlug] > 0 && (
                  <BulkInputBar
                    selectedTabSlug={this.props.selectedTabSlug}
                    tabCount={this.props.counts[this.props.selectedTabSlug]}
                  />
                )}
                <StoryList
                  navigate={this.props.navigate}
                  features={this.props.features}
                  stories={this.props.stories}
                  showPublicPreviewLink={this.props.showPublicPreviewLink}
                  canDeleteStory={this.props.canDeleteStory}
                  updateStoryIds={this.updateStoryIds}
                  bulkSelectedStoryIds={this.props.bulkSelectedStoryIds}
                  isBulkActionEnabled={this.props.isBulkActionEnabled}
                  allStoriesSelected={this.props.allStoriesSelected}
                  storyTemplates={this.props.storyTemplates}
                  setStoriesOnFilterChange={this.setStoriesOnFilterChange}
                  switchToDeleteStoryRoute={(id: string) =>
                    this.props.switchToDeleteStoryRoute(id, this.props.selectedTabSlug)
                  }
                  isAllTabSelected={this.props.selectedTabSlug === "all"}
                  loadMoreStories={this.loadMoreStories}
                  totalStories={this.props.hits}
                  errors={this.props.errors}
                  showLoadingIndicator={this.props.showLoadingIndicator}
                  loadData={this.props.loadData}
                  isCurrentWindowIsInSlider={isCurrentWindowIsInSlider}
                />
              </div>
            </Panel>
          </div>
        </div>

        <FiltersInspector
          navigate={this.props.navigate}
          showFilterInspector={this.props.showFilterInspector}
          showSavedFilterList={this.props.showSavedFilterList}
          storyTemplates={this.props.storyTemplates}
          switchToTab={this.props.onClose}
          selectedTabSlug={this.props.selectedTabSlug}
        />

        <BulkActionInspector
          showBulkActionInspector={this.props.showBulkActionInspector}
          applyAccessLevel={this.applyAccessLevel}
          onClose={() => this.props.onClose(this.props.selectedTabSlug)}
        />

        <SaveFilterInspector
          showSaveFilterInspector={this.props.showSaveFilterInspector}
          onClose={() => {
            this.props.onClose(this.props.selectedTabSlug);
            this.props.removeErrors("filterName");
          }}
          saveFilters={(filterName: string) =>
            this.props.saveFilters(this.props.currentFilterSet, filterName, this.props.selectedTabSlug)
          }
          showLoadingIndicator={this.props.showLoadingIndicator}
        />

        <ConfirmationModal
          showConfirmationModal={this.props.showStoryDeleteModal}
          headerText={t("workspace.confirmation_modal.delete_confirmation")}
          text={t("workspace.delete_message")}
          confirmButtonLabel={t("workspace.confirmation_modal.delete")}
          cancelButtonLabel={t("workspace.confirmation_modal.cancel")}
          onConfirm={() => this.props.deleteStory(this.props.selectedTabSlug, this.props.loadTab)}
          onCancel={() => this.props.onClose(this.props.selectedTabSlug)}
          onClose={() => this.props.onClose(this.props.selectedTabSlug)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: OwnProps) => {
  return {
    tabs: state.config.tabs,
    features: state.features,
    stories: state.workspace.stories,
    hits: state.workspace.hits,
    storyTemplates: state.config.storyTemplates,
    counts: state.workspace.aggregations.status,
    selectedTabSlug: ownProps.match.params.tabSlug,
    currentFilterSet: state.workspace.currentFilterSet,
    showSavedFilterList: ownProps.showSavedFilterList,
    sources: state.config.sources,
    isBulkActionEnabled: state.workspace.isBulkActionEnabled,
    bulkSelectedStoryIds: state.workspace.bulkSelectedStoryIds,
    bulkAccessLevelValue: state.workspace.bulkAccessLevelValue,
    allStoriesSelected: state.workspace.allStoriesSelected,
    showLoadingIndicator: state.workspace.showLoadingIndicator,
    showPublicPreviewLink: state.features.showPublicPreviewLink,
    canDeleteStory: state.features.canDeleteStory,
    selectedSavedFilter: state.workspace.selectedSavedFilter,
    errors: state.workspace.errors,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    title: "workspace",
    pageReferer: state.workspace.pageReferer,
    isCurrentWindowIsInSlider: get(state, ["slider", "currentWindow", "isInSlider"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: OwnProps) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    loadData: (defaultFilter: { [key: string]: any }) =>
      dispatch(fetchWorkspaceData(ownProps.match.params.tabSlug, defaultFilter)),
    loadSearchData: (defaultFilter: { [key: string]: any }) =>
      dispatch(fetchWorkspaceSearchData(ownProps.match.params.tabSlug, defaultFilter)),
    onClose: (tabSlug: string) => {
      navigate(WORKSPACE_TABS_PATH, { tabSlug });
      dispatch({ type: actions.WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE });
    },
    loadTab: (tabSlug: string) => {
      navigate(WORKSPACE_TABS_PATH, { tabSlug });
      dispatch({ type: actions.WORKSPACE_RESET_STORIES });
      dispatch({ type: actions.WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE });
    },
    switchToTab: (tabSlug: string) => {
      if (ownProps.match.params.tabSlug !== tabSlug) {
        navigate(WORKSPACE_TABS_PATH, { tabSlug });
        dispatch({ type: actions.WORKSPACE_RESET_STORIES });
        dispatch({ type: actions.WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE });
      }
    },
    toggleFiltersInspector: (tabSlug: string) => navigate(WORKSPACE_FILTERS_PATH, { tabSlug }),
    updateFilter: (key: string, value: any) => {
      dispatch({
        type: actions.WORKSPACE_UPDATE_FILTER,
        payload: { key, value }
      });
      return Promise.resolve();
    },
    enableBulkAction: () => dispatch({ type: actions.WORKSPACE_ENABLE_BULK_ACTION }),
    updateStoryIds: (id: string) => {
      dispatch({ type: actions.WORKSPACE_UPDATE_STORY_IDS, id });
      return Promise.resolve();
    },
    saveFilter: (tabSlug: string) => navigate(WORKSPACE_SAVE_FILTER_PATH, { tabSlug }),
    saveFilters: (filterSet: FilterSet, filterName: string, tabSlug: string) =>
      dispatch(saveFiltersAction(filterSet, filterName, tabSlug)),
    updateBulkSelected: (tabCounts: number) => dispatch({ type: actions.WORKSPACE_UPDATE_BULK_SELECTED, tabCounts }),
    clearFilters: () => {
      dispatch({ type: actions.WORKSPACE_RESET_STORIES });
      dispatch({ type: actions.WORKSPACE_RESET_FILTER });
      dispatch(fetchWorkspaceData(ownProps.match.params.tabSlug));
    },
    resetFilter: () => dispatch({ type: actions.WORKSPACE_RESET_FILTER }),
    switchToDeleteStoryRoute: (storyId: string, tabSlug: string) =>
      navigate(WORKSPACE_DELETE_STORY_PATH, { storyId, tabSlug }),
    deleteStory: (selectedTabSlug: string, onClose: (tabSlug: string) => void) =>
      dispatch(deleteWorkspaceStory(ownProps.match.params.storyId, selectedTabSlug, onClose)),
    removeErrors: (key: string) => dispatch({ type: actions.WORKSPACE_REMOVE_ERRORS, payload: key }),
    updateSavedFilter: (savedFilter: SavedFilter, filterSet: FilterSet) =>
      dispatch(updateFilterAction(savedFilter, filterSet)),
    resetStories: () => dispatch({ type: actions.WORKSPACE_RESET_STORIES }),
    notificationSuccess: (message: string) => dispatch({ type: NOTIFICATION_SUCCESS, payload: { message } }),
    notificationError: (message: string) => dispatch({ type: NOTIFICATION_ERROR, payload: { message } }),
    updatePageReferer: (referer: string | null) => dispatch(updatePageReferer(referer))
  };
};

export default compose<any, any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({
    HeaderComponent: Header,
    ActionBarComponent: WorkspaceActionBar
  })
)(Workspace);

export { Workspace };
